import React, { useMemo } from 'react';
import {
  ServiceData,
  SlotService,
  SlotServices,
} from '../../../../../utils/state/types';
import { ServicePaymentDropdown } from './ServicePaymentDropdown/ServicePaymentDropdown';
import { getServiceSlotIdentifier, mapToArray } from '../../../../../utils';
import {
  getDefaultPaymentOptionId,
  getPaymentTypeFromOption,
  getSlotServicePaymentOptions,
  sortPaymentOptions,
} from '../../../../../utils/payment';
import { Member } from '@wix/ambassador-members-ng-api/types';
import {
  PaymentMethod,
  PaymentOption,
  PaymentType,
  ReservedPaymentOptionIds,
} from '../../../../../types/types';
import { useTranslation, useExperiments } from '@wix/yoshi-flow-editor';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { useSettings } from '@wix/tpa-settings/react';
import { useFormActions } from '../../../Hooks/useFormActions';
import { FormStatus } from '../../../../../types/form-state';
import {
  renderSlotDynamicPricesDropdown,
  shouldRenderDynamicPriceDropdown,
} from '../../PriceOptionDropdownContainer/PriceOptionDropdownContainer';
import { getErrorByType } from '../../../../../utils/errors/errors';
import { DynamicPriceErrorType, FormError } from '../../../../../types/errors';
import { OfferedAsType } from '@wix/bookings-uou-types';
import sortBy from 'lodash/sortBy';
import { getDefaultPaymentType } from '../../../../../utils/state/utils/utils';

export type MultiPaymentSelectionContainerProps = {
  serviceData: ServiceData;
  memberDetails?: Member;
  numberOfParticipants: number;
  dateRegionalSettingsLocale: string;
  isPricingPlanInstalled: boolean;
  status: FormStatus;
  errors: FormError[];
};

export const getMultiPaymentSelectionContainer = (
  props: MultiPaymentSelectionContainerProps,
) => {
  const paymentSelection = RenderMultiPaymentSelection(props);

  if (!paymentSelection) {
    return null;
  }

  return <>{paymentSelection}</>;
};

export const MultiPaymentSelectionContainer: React.FC<
  MultiPaymentSelectionContainerProps
> = (props) => {
  const paymentSelection = RenderMultiPaymentSelection(props);

  if (!paymentSelection) {
    return null;
  }

  return <>{paymentSelection}</>;
};

const RenderMultiPaymentSelection = ({
  serviceData,
  isPricingPlanInstalled,
  numberOfParticipants,
  dateRegionalSettingsLocale,
  memberDetails,
  status,
  errors,
}: MultiPaymentSelectionContainerProps) => {
  const { setDefaultPaymentOptions, onSelectedVariants, clearErrorByTypes } =
    useFormActions();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const dateAndTimeFormatter = useMemo(
    () => new DateTimeFormatter(dateRegionalSettingsLocale),
    [dateRegionalSettingsLocale],
  );

  const slotsList = mapToArray<SlotService>(serviceData.slotServices);
  const sortOrder = getDefaultPaymentType(
    settings,
    mapToArray<SlotService>(serviceData.slotServices)[0].service.paymentTypes,
  );
  const lineItemIds = slotsList
    .map((slotService) => slotService.nestedSlot.lineItemId)
    .join(',');
  const servicesPaymentOptionsMap: { [key: string]: PaymentOption[] } =
    useMemo(() => {
      const result: { [key: string]: PaymentOption[] } = {};
      const objectToUpdate: {
        [key: string]: {
          selectedPaymentOption: PaymentOption;
          selectedPaymentType: PaymentMethod;
        };
      } = {};
      slotsList.forEach((service) => {
        result[getServiceSlotIdentifier(service.nestedSlot)] =
          sortPaymentOptions(
            getSlotServicePaymentOptions({
              slotService: service,
              isPricingPlanInstalled,
              numberOfParticipants,
              t,
              dateAndTimeFormatter,
              dateRegionalSettingsLocale,
              experiments,
            }),
            sortOrder,
          );

        const newDefaultId = getDefaultPaymentOptionId({
          servicePayment: service.service.payment,
          isPricingPlanInstalled,
          settings,
          memberships: service.memberships,
          isDynamicPricingCustomOptions:
            !!service.dynamicPriceInfo?.customOptions,
          possiblePlans: service.possiblePlans,
        });

        if (newDefaultId) {
          let newPaymentOption;
          if (newDefaultId === ReservedPaymentOptionIds.BuyAPricingPlan) {
            newPaymentOption = result[
              getServiceSlotIdentifier(service.nestedSlot)
            ].find((option) => option.type === PaymentType.BUY_PLAN);
          } else {
            newPaymentOption = result[
              getServiceSlotIdentifier(service.nestedSlot)
            ].find((option) => option.id === newDefaultId);
          }
          if (newPaymentOption) {
            const newPaymentType = getPaymentTypeFromOption(newPaymentOption);
            objectToUpdate[getServiceSlotIdentifier(service.nestedSlot)] = {
              selectedPaymentOption: newPaymentOption,
              selectedPaymentType: newPaymentType!,
            };
          }
        }
      });

      setDefaultPaymentOptions(objectToUpdate);

      return result;
    }, [lineItemIds, memberDetails?.id, dateRegionalSettingsLocale, sortOrder]);
  const getServiceLabel = (service: SlotService) => {
    if (
      slotsList.filter((s) => s.service.name === service.service.name)
        .length === 1
    ) {
      return t('app.bookings-form.payment-section.service-dropdown.label', {
        serviceName: service.service.name,
      });
    } else {
      return t(
        'app.bookings-form.payment-section.service-dropdown.label-at-time',
        {
          serviceName: service.service.name,
          serviceTime: dateAndTimeFormatter.formatTime(
            service.nestedSlot.startDate,
          ),
        },
      );
    }
  };

  const firstDynamicPriceError = getErrorByType({
    errorType: DynamicPriceErrorType,
    errors,
  });

  const isServiceWithPricingPlan = (slotService: SlotService) => {
    return slotService.service.payment.offeredAs.includes(
      OfferedAsType.PRICING_PLAN,
    );
  };

  const isAllServicesHaveOnePaymentOption = () => {
    return slotsList.every((slotService) => {
      return (
        servicesPaymentOptionsMap[
          getServiceSlotIdentifier(slotService.nestedSlot)
        ].length === 1
      );
    });
  };

  const shouldRenderPaymentDropdown = (slotService: SlotService): boolean => {
    const casesNotToShow =
      ((slotService.paymentDetails.isFree ||
        slotService.paymentDetails.price === 0) &&
        !isServiceWithPricingPlan(slotService)) ||
      servicesPaymentOptionsMap[
        getServiceSlotIdentifier(slotService.nestedSlot)
      ].length === 1;

    return !casesNotToShow;
  };

  const shouldRenderSlotPayment = (slotService: SlotService) => {
    return (
      shouldRenderPaymentDropdown(slotService) ||
      shouldRenderDynamicPriceDropdown(slotService)
    );
  };
  const isAnyServiceValidToView = () => {
    return slotsList.some((s) => shouldRenderSlotPayment(s));
  };
  const sortPaymentOptionsByStartTime = (slotServices: SlotServices) => {
    return sortBy(
      Object.entries(slotServices),
      (y) => y[1].nestedSlot.startDate,
    );
  };

  if (slotsList.length === 1) {
    const slotService = slotsList[0];

    if (
      !shouldRenderPaymentDropdown(slotService) &&
      !shouldRenderDynamicPriceDropdown(slotService)
    ) {
      return null;
    }

    return (
      <>
        {shouldRenderPaymentDropdown(slotService) && (
          <ServicePaymentDropdown
            service={slotService}
            paymentOptions={
              servicesPaymentOptionsMap[
                getServiceSlotIdentifier(slotService.nestedSlot)
              ]
            }
            label={getServiceLabel(slotService)}
            key={getServiceSlotIdentifier(slotService.nestedSlot)}
          />
        )}
        {renderSlotDynamicPricesDropdown(
          t,
          dateAndTimeFormatter,
          experiments,
          slotService,
          onSelectedVariants,
          clearErrorByTypes,
          serviceData,
          status,
          firstDynamicPriceError,
        )}
      </>
    );
  }

  const forceRenderPaymentDropdowns = !isAllServicesHaveOnePaymentOption();

  if (!forceRenderPaymentDropdowns || !isAnyServiceValidToView()) {
    return null;
  }

  return (
    <>
      {sortPaymentOptionsByStartTime(serviceData.slotServices).map(
        ([slotIdentifier, slotService]) => {
          return (
            <>
              {(forceRenderPaymentDropdowns ||
                shouldRenderPaymentDropdown(slotService)) && (
                <ServicePaymentDropdown
                  service={slotService}
                  paymentOptions={servicesPaymentOptionsMap[slotIdentifier]}
                  label={getServiceLabel(slotService)}
                  key={getServiceSlotIdentifier(slotService.nestedSlot)}
                />
              )}
              {renderSlotDynamicPricesDropdown(
                t,
                dateAndTimeFormatter,
                experiments,
                slotService,
                onSelectedVariants,
                clearErrorByTypes,
                serviceData,
                status,
                firstDynamicPriceError,
              )}
            </>
          );
        },
      )}
    </>
  );
};
